import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import translation from './translation/i18n';
import './index.css';

const App = () => {

  return (
        <Router>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
          </Routes>
       </Router>
  );

}

export default App;