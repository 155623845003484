import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { sendResetPasswordEmail , submitFileUpload } from "../services/api";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function UploadFileField(props) {

    const {
        t,
        language
    } = props.i18n;

    const [uploadBtnTitle, setUploadBtnTitle] = useState(props.title || t('UPLOAD'));

    const handleUploadFile = (event) => {
        const selectedFile = event.target.files[0];
        console.log(selectedFile);
        if (selectedFile) {
            setUploadBtnTitle(t('UPLOADING'));
            const formdata = new FormData();
            formdata.append('file', selectedFile, event.target.value);
            submitFileUpload(formdata)
                .then((response) => {
                    if (response == null) {
                        setUploadBtnTitle('Upload error, Try again');
                    } else {
                        props.handleResponse(response.Location);
                        setUploadBtnTitle('File uploaded');
                    }
                })
                .catch(() => {
                    setUploadBtnTitle('Upload error, Try again');
                });
        }
    }


    return (
        <>
            <IconButton className={'mt-2'} style={{ fontSize: 16, width: '100%', border: '1px solid #cbc8c8', borderRadius: '5px', padding: '15px 0' }} aria-label="upload file" component="label">
                <CloudUploadIcon className={'mr-2'} /> {' '} {uploadBtnTitle}
                <input
                    autoFocus
                    hidden
                    name="file"
                    id={'file'}
                    accept={'application/pdf,application/msword,.doc,.docx'}
                    required
                    onChange={handleUploadFile}
                    type="file" />
            </IconButton>
        </>
    );
}

export default withTranslation()(UploadFileField);