import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {Button, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {sendResetPasswordEmail} from "../services/api";
import ChooseCountryField from "../services/chooseCountryField";
import UploadFileField from "../services/uploadFileField";


function TataorRequestForm(props) {

    const {
        t,
        language
    } = props.i18n;

    const [error, setError] = useState(null);
    const [formContent , setFormContent] = useState({});

    const handleFormChange = (ev) => {
        const newForm = { ...formContent };
        newForm[ev.target.name] = ev.target.value;
        setFormContent(newForm);
    };

    const handleFromSubmit = (ev) => {
        //
    };


    return (
        <>
            <TextField
                label={t('FIRST_NAME')}
                fullWidth
                name="firstName"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />
            <TextField
                label={t('LAST_NAME')}
                fullWidth
                name="lastName"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />

            <TextField
                label={t('EMAIL')}
                type={'email'}
                name="email"
                fullWidth
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />


            <TextField
                label={t('PHONE')}
                fullWidth
                name="phone"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />

            <ChooseCountryField handleResponse={(res)=>{
                console.log('country',res);
            }} />


            <TextField
                label={t('Write your message')}
                fullWidth
                multiline
                rows={3}
                name="message"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />



            <Button variant="contained" className={'mt-2 mb-3'}
                    onClick={handleFromSubmit}
                    fullWidth
            >
                {t('SUBMIT')}
            </Button>
        </>
    );
}

export default withTranslation()(TataorRequestForm);