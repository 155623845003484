import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {Autocomplete, Button, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {sendResetPasswordEmail} from "../services/api";
import ChooseCountryField from "../services/chooseCountryField";
import UploadFileField from "../services/uploadFileField";


function IssueReportForm(props) {

    const {
        t,
        language
    } = props.i18n;

    const [error, setError] = useState(null);
    const [formContent, setFormContent] = useState({type: 'issueReportForm'});
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleFormChange = (ev) => {
        setError(null);
        const newForm = {...formContent};
        newForm[ev.target.name] = ev.target.value;
        setFormContent(newForm);
    };


    const handleAutoCompleteFormChange = (name , value) => {
        setError(null);
        const newForm = { ...formContent };
        newForm[name] = value.label;
        setFormContent(newForm);
    };


    const handleFromSubmit = () => {
        if (!formContent.firstName) {
            setError(t('First name is required.'));
            return;
        }
        if (!formContent.lastName) {
            setError(t('Last name is required.'));
            return;
        }
        if (!formContent.email) {
            setError(t('Email is required.'));
            return;
        } else {
            if (!isEmail(formContent.email)) {
                setError(t('Enter a valid email address'));
            }
        }

        if (!formContent.phone) {
            setError(t('Phone is required.'));
            return;
        }

        if (!formContent.country) {
            setError(t('Country is required.'));
            return;
        }
        if (!formContent.feedback_type) {
            setError(t('Choose feedback type'));
            return;
        }



        setSubmitDisabled(true);
        props.handleResponce(formContent);
    };


    const isEmail = (email) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(email);
    };

    const feedback = [
        {label : 'General issue' },
        {label : 'Content issue' },
        {label : 'Software issue' },
        {label : 'Store or website issue' },
    ];

    return (
        <>
            <TextField
                label={t('FIRST_NAME')}
                fullWidth
                name="firstName"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />
            <TextField
                label={t('LAST_NAME')}
                fullWidth
                name="lastName"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />
            <TextField
                label={t('EMAIL')}
                type={'email'}
                name="email"
                fullWidth
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />

            <TextField
                label={t('PHONE')}
                fullWidth
                name="phone"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />


            <ChooseCountryField handleResponse={(res)=>{
                setError(null);
                const newForm = {...formContent};
                newForm.country = res;
                setFormContent(newForm);
            }} />

            <Autocomplete
                className={'mt-2'}
                disablePortal
                options={feedback}
                onChange={(event, value)=>handleAutoCompleteFormChange('feedback_type' , value)}
                renderInput={(params) => <TextField {...params} label={t('FEEDBACK')} />}
            />

            <TextField
                label={t('Write your message')}
                fullWidth
                multiline
                rows={3}
                name="message"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />


            <UploadFileField handleResponse={(res)=>{
                setError(null);
                const newForm = {...formContent};
                newForm.file = res;
                setFormContent(newForm);
            }} />


            <p style={{color: 'red', textAlign: 'center'}}>{error}</p>

            <Button
                disabled={submitDisabled}
                variant="contained"
                className={'mt-2 mb-3'}
                onClick={handleFromSubmit}
                fullWidth
            >
                {t('SUBMIT')}
            </Button>
        </>
    );
}

export default withTranslation()(IssueReportForm);