import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Button, Container, Divider, IconButton, InputBase, Stack, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloseIcon from '@mui/icons-material/Close';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useIdleTimer } from 'react-idle-timer'
import Language from '@mui/icons-material/Language';
import {
    checkLoginDetails,
    getHelpQuestions,
    getMultipleQuestions,
    getRelatedQuestionDetails,
    getUserGeolocationDetails,
    sendResetPasswordEmail,
    generateNewSession,
    createNewActivity,
    updateActivity,
    submitFormContent
} from '../services/api';
import {storeUserDetails, storeUserGeoData, storeUserSteps, storeUserSession} from '../redux/userData';
import {makeStyles} from '@mui/styles';
import localize from '../services/localize';
import FullScreen from "react-fullscreen-crossbrowser";
import ResetPasswordForm from "../components/resetPasswordForm";
import ReturnRequestForm from "../components/returnRequestForm";
import PassGuaranteeRequestForm from "../components/passGuaranteeRequestForm";
import PartnerSearchForm from "../components/partnerSearchForm";
import ActivationForm from "../components/activationForm";
import BankWireProformaForm from "../components/bankWireProformaForm";
import SwitchToPrcForm from "../components/switchToPrcForm";
import TataorRequestForm from "../components/tataorRequestForm";
import IncorrectInformationForm from "../components/incorrectInformationForm";
import FeedbackForm from "../components/feedbackForm";
import OneYearRenewRequestForm from "../components/oneYearRenewRequestForm";
import ThreeMonthsRenewRequestForm from "../components/threeMonthsRenewRequestForm";
import IssueReportForm from "../components/issueReportForm";
import DidNotPassForm from "../components/didNotPassForm";
import GeneralForm from "../components/generalForm";

const useStyles = makeStyles(theme => ({

    main: {
        maxHeight: 200,
        height: 300,
        flexDirection: 'row !important',
    },

    mainLTR: {
        maxHeight: 200,
        height: 300,
        flexDirection: 'row-reverse !important',
    },
    header: {
        display: 'flex',
        alignItems:'center',
     '& img':{
        maxWidth:48,
         margin:'0 10px',
        }
        },
    botBubble: {
        background: '#FFF',
        color: '#000',
        fontSize: '0.9rem !important',
        padding: 15,
        borderRadius: '25px 25px 25px 0',
        direction: 'rtl',
        width: 'fit-content',
        lineHeight: '25px',
        marginLeft: 'auto !important',
        '& a': {
            marginTop: '10px',
            marginBottom: '10px',
            display: 'block',
            textAlign: 'left',
            lineHeight: '20px',
            textDecoration: 'underline',
        },
        '& ol': {
            lineHeight: '30px',
            listStyle: 'arabic-indic',
            listStylePosition: 'inside',
        }
    },
    botBubbleLTR: {
        background: '#FFF',
        color: '#000',
        fontSize: '0.9rem !important',
        padding: 15,
        borderRadius: '25px 25px 0',
        direction: 'ltr',
        width: 'fit-content',
        lineHeight: '25px',
        marginRight: 'auto !important',
        '& a': {
            marginTop: '10px',
            marginBottom: '10px',
            display: 'block',
            textAlign: 'left',
            lineHeight: '20px',
            textDecoration: 'underline',
        },
        '& ol': {
            lineHeight: '30px',
            listStyle: 'decimal-leading-zero',
            listStylePosition: 'inside',
        }
    },
    userBubble: {
        background: '#99cc33',
        color: '#fff',
        padding: 15,
        fontSize: '0.9rem  !important',
        lineHeight: '36px',
        direction: 'rtl',
        borderRadius: '25px 25px 0',
        width: 'fit-content',
        marginRight: 'auto !important'

    },
    userBubbleLTR: {
        background: '#99cc33',
        color: '#fff',
        padding: 15,
        fontSize: '0.9rem  !important',
        lineHeight: '36px',
        direction: 'ltr',
        borderRadius: '25px 25px 25px 0',
        width: 'fit-content',
        marginLeft: 'auto !important'

    },
    optionButton: {
        letterSpacing: '0 !important',
        direction: 'rtl',
        width:'auto !important',
        background:'#FFF !important',
        color:'#152D52 !important',
        border: '1px solid #152D52 !important',
        textAlign:'right',
        '&:hover': {
            background: '#99cc33 !important',
            color: '#fff !important',
            border: '1px solid #99cc33 !important',
        }
    },
    optionButtonLTR: {
        letterSpacing: '0 !important',
        direction: 'ltr',
        width:'auto !important',
        background:'#FFF !important',
        color:'#152D52 !important',
        border: '1px solid #152D52 !important',
        textAlign:"left",
        '&:hover': {
            background: '#99cc33 !important',
            color: '#fff !important',
            border: '1px solid #99cc33 !important',
        }
    },
    input: {
        fontSize: '0.9rem',
    },
    headerButton: {minWidth: 'auto !important'}
}));

function Home(props) {
    const {
        t,
        language
    } = props.i18n;

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const sessionState = useSelector((state) => state.userData);
    const [direction, setDirection] = useState(null);
    const forLanguage = language.split('-')[0] || 'all';
    const [errorMessage, setErrorMessage] = useState('');
    const [sessionStarted, setSessionStarted] = useState(true);
    const [messageText, setMessageText] = useState('');
    const [inputDisabled, setInputDisabled] = useState(true);
    const [passwordInput, setPasswordInput] = useState(false);
    const [inputPlaceholder, setInputPlaceholder] = useState(t('choose from options'));
    const [lastStep, setLastStep] = useState({});
    const [isFullScreen, setIsFullScreen] = useState(false);
    const chatRef = useRef(null);
    const [activityId, setActivityId] = useState(null);
    const [showLastMessage , setShowLastMessage] = useState(false);

    if (!direction) {
        if (language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }

    const languageSwitcher = (language) => {
        i18next.changeLanguage(language).then(r =>
            window.location.reload(false)
        );
    }

    // Scroll function
    useEffect(() => {
        if (sessionState && sessionState.session._id && sessionState.geoData.IPv4 && sessionState.steps.length > 3) {
            scrollToBottom();
            if (!activityId) {
                const data = {
                    sessionId: sessionState.session._id,
                    content: sessionState,
                    title: sessionState.geoData.IPv4
                }
                    createNewActivity(data).then((res) => {
                        setActivityId(res.id);
                    });
            } else {
                const data = {
                    sessionId: sessionState.session._id,
                    content: sessionState,
                    title: sessionState.geoData.IPv4
                }
                updateActivity(activityId, data);
            }
        }
    }, [sessionState]);

    function scrollToBottom() {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current?.scrollHeight;
        }
    }
    // End Scroll function

    useEffect(() => {
        if (sessionStarted) {

            // Generate New Session 
            generateNewSession().then((response) => {
                dispatch(storeUserSession(response));
            });

            // Get User Location Data...
            getUserGeolocationDetails()
                .then((response) => {
                    dispatch(storeUserGeoData(response));
                });
            // Insert Welcome Messages...
            handleStoreSessionSteps(randomNumber(), t('HELLO_TITLE'), 'p');
            handleStoreSessionSteps(randomNumber(), t('HELLO_SUBTITLE'), 'p');
            handleStoreSessionSteps(randomNumber(), t('What would you like me to call you'), 'p', 'bot', 'input', {
                type: 'question',
                custom: 'username',
                question: t('What would you like me to call you')
            });
        }
        setSessionStarted(false);
    }, [dispatch]);

    const handleStoreSessionSteps = (id, val, type = '', from = 'bot', action = {}, trigger = {}, form = '') => {

        const step = {
            id: id,
            message: val,
            type: type,
            from: from,
            action: action,
            trigger: trigger,
            form: form,
        };
        if (action === 'input') {
            setInputDisabled(false);
            setInputPlaceholder(trigger.question);
        } else {
            setInputDisabled(true);
        }
        setLastStep(step);
        dispatch(storeUserSteps(step));

    };

    const handleStoreUserDetails = (key, value) => {

        dispatch(storeUserDetails({
            ...sessionState.userDetails,
            [key]: value
        }));

    };

    function onInputChange(ev) {
        setMessageText(ev.target.value);
    }

    function onMessageSubmit(ev) {
        ev.preventDefault();
        if (messageText === '') {
            return;
        }
        setMessageText('');
        setPasswordInput(false);
        setInputPlaceholder(t('choose from options'));
        switch (lastStep?.trigger?.custom) {
            case 'username':
                handleStoreSessionSteps(randomNumber(), messageText, 'p', 'user');
                handleStoreUserDetails('name', messageText);
                handleStoreSessionSteps(randomNumber(), t('Hi') + messageText + t('How would you like to start?'),
                    'radio',
                    'bot',
                    'radio',
                    {
                        type: 'question',
                        custom: 'start',
                        question: '',
                        options: [{
                            value: 0,
                            text: t('Continue with my PRC account (sign in required)')
                        }, {
                            value: 1,
                            text: t('Continue as a guest')
                        }]
                    });
                break;
            case 'userEmail':
                if (isEmail(messageText)) {
                    setErrorMessage('');
                    setPasswordInput(true);
                    handleStoreSessionSteps(8, messageText, 'p', 'user');
                    handleStoreUserDetails('email', messageText);
                    handleStoreSessionSteps(9, t('Enter your login password'), 'p', 'bot', 'input', {
                        type: 'question',
                        custom: 'userPassword',
                        question: t('Enter your login password')
                    });

                } else {
                    setErrorMessage(t('Enter a valid email address'));
                }
                break;
            case 'userPassword':
                handleStoreSessionSteps(randomNumber(), '********', 'p', 'user');
                handleStoreUserDetails('password', '********');
                handleStoreSessionSteps(randomNumber(), t('Please wait, I am trying to access your PC account'), 'p', 'bot', '', {
                    type: 'question',
                    custom: 'login',
                });

                const email = sessionState.userDetails.email;
                checkLoginDetails(email, messageText)
                    .then((res) => {
                        if (res) {
                            handleStoreUserDetails('prcAccount', res);
                            handleStoreSessionSteps(randomNumber(), t('You are logged in successfully'), 'p', 'bot', '', {
                                type: 'question',
                            });
                            handleStoreUserDetails('isCandidate', 'true');
                            handleStoreSessionSteps(randomNumber(), t('How I can help you?'),
                                'radio',
                                'bot',
                                'radio',
                                {
                                    type: 'question',
                                    custom: 'firstQuestion',
                                    question: '',
                                    options: [
                                        {
                                            value: 0,
                                            text: t('I have a question')
                                        },
                                        {
                                            value: 1,
                                            text: t('I want more information')
                                        },
                                    ]
                                });

                        } else {
                            handleStoreSessionSteps(randomNumber(), t('Login Failed'),
                                'radio',
                                'bot',
                                'radio',
                                {
                                    type: 'question',
                                    custom: 'start',
                                    question: '',
                                    options: [{
                                        value: 0,
                                        text: t(t('Try logging in again'))
                                    }, {
                                        value: 1,
                                        text: t('Continue as a guest')
                                    }, {
                                        value: 2,
                                        text: t('I forgot my password')
                                    }]
                                });
                        }
                    });

                break;
            case 'resetPasswordEmail':
                if (isEmail(messageText)) {
                    setErrorMessage('');
                    setPasswordInput(true);
                    handleStoreSessionSteps(8, messageText, 'p', 'user');
                    handleStoreUserDetails('email', messageText);
                    sendResetPasswordEmail(messageText).then((res) => {
                        handleStoreSessionSteps(randomNumber(), t('Reset password link was sent to your email'), 'p');
                    })

                } else {
                    setErrorMessage(t('Enter a valid email address'));
                }
                break;
            default:
                handleStoreSessionSteps(randomNumber(), messageText, 'p', 'user');
        }
    }

    const handleQuestionOptionSelect = (value, text, stepIndex) => {

        if (sessionState?.steps[stepIndex]?.action === 'radio') {

            if (sessionState?.steps[stepIndex]?.trigger?.custom === 'start') {
                handleStoreSessionSteps(randomNumber(), text, 'p', 'user');
                if (value === 0) {
                    setPasswordInput(false);
                    handleStoreSessionSteps(randomNumber(), t('Enter your login email'), 'p', 'bot', 'input', {
                        type: 'question',
                        custom: 'userEmail',
                        question: t('Enter your login email')
                    });
                }
                if (value === 1) {

                    handleStoreSessionSteps(randomNumber(), t('How I can help you?'),
                        'radio',
                        'bot',
                        'radio',
                        {
                            type: 'question',
                            custom: 'firstQuestion',
                            question: '',
                            options: [
                                {
                                    value: 0,
                                    text: t('I have a question')
                                },
                                {
                                    value: 1,
                                    text: t('I want more information')
                                }
                            ]
                        });

                }
                if (value === 2) {
                    handleStoreSessionSteps(randomNumber(), t('Enter your PRC account email address'), 'p', 'bot', 'input', {
                        type: 'question',
                        custom: 'resetPasswordEmail',
                        question: t('Enter your PRC account email address')
                    });
                }
            }
            if (sessionState?.steps[stepIndex]?.trigger?.custom === 'firstQuestion') {
                if (value === 0) {
                    handleStoreSessionSteps(randomNumber(), t('I have a question'), 'p', 'user');
                    // Get Questions
                    const showFor = (sessionState?.userDetails?.isCandidate === 'true') ? 'candidate' : 'guest';
                    let questionsArray = [];
                    getHelpQuestions({
                        isStart: true,
                        showFor: showFor,
                        isFaqQuestion: false,
                        forLanguage: forLanguage,
                    })
                        .then((res) => {
                            // eslint-disable-next-line array-callback-return
                            (res.results || []).map((item, index) => {
                                questionsArray.push({
                                    value: item._id,
                                    text: localize(item.text),
                                });
                            });
                            handleStoreSessionSteps(randomNumber(), '',
                                'radio',
                                'bot',
                                'radio',
                                {
                                    type: 'question',
                                    custom: 'options',
                                    question: '',
                                    options: questionsArray,
                                });
                        });
                }
                if (value === 1) {
                    // Get FAQs
                    handleStoreSessionSteps(randomNumber(), t('I want more information'), 'p', 'user');
                    // Get Questions
                    const showFor = (sessionState?.userDetails?.isCandidate === 'true') ? 'candidate' : 'guest';
                    let faqsArray = [];
                    getHelpQuestions({
                        isStart: true,
                        showFor: showFor,
                        isFaqQuestion: true,
                        forLanguage: forLanguage,
                    })
                        .then((res) => {
                            // eslint-disable-next-line array-callback-return
                            (res.results || []).map((item, index) => {
                                faqsArray.push({
                                    value: item._id,
                                    text: localize(item.text),
                                });
                            });
                            handleStoreSessionSteps(randomNumber(), '',
                                'radio',
                                'bot',
                                'radio',
                                {
                                    type: 'question',
                                    custom: 'options',
                                    question: '',
                                    options: faqsArray,
                                });
                        });
                }
            }
            if (sessionState?.steps[stepIndex]?.trigger?.custom === 'options') {
                const questionId = value;
                getRelatedQuestionDetails(questionId)
                    .then((res) => {
                        handleStoreSessionSteps(randomNumber(), localize(res.text), 'p', 'user');
                        // Check Question Type and action
                        const questionType = res.type;
                        const questionActionType = res.defaultAction?.type;
                        const showFor = (sessionState?.userDetails?.isCandidate === 'true') ? 'candidate' : 'guest';
                        if (questionType === 'radioButton' && questionActionType === 'askQuestions') {
                            // Get related questions from default action
                            const questionIds = res.defaultAction.questionIds;
                            let questionsArray = [];
                            getMultipleQuestions({
                                questionIds: questionIds,
                                showFor: showFor,
                                forLanguage: forLanguage,
                            })
                                .then((res) => {
                                    (res.results || []).map((item, index) => {
                                        questionsArray.push({
                                            value: item._id,
                                            text: localize(item.text),
                                        });
                                    });
                                    handleStoreSessionSteps(randomNumber(), '',
                                        'radio',
                                        'bot',
                                        'radio',
                                        {
                                            type: 'question',
                                            custom: 'options',
                                            question: '',
                                            options: questionsArray,
                                        });
                                });
                        }
                        if (questionType === 'textAnswer') {
                            if (res.showForm) {
                                setTimeout(() => {
                                    setShowLastMessage(true);
                                }, 10000);
                                handleStoreSessionSteps(randomNumber() + res._id, localize(res.description), 'html', 'bot', {}, {}, res.showForm);
                            } else {
                                setShowLastMessage(true);
                                handleStoreSessionSteps(randomNumber() + res._id, localize(res.description), 'html');
                            }

                        }
                    });
            }
            if (sessionState?.steps[stepIndex]?.trigger?.custom === 'end') {
                handleStoreSessionSteps(randomNumber(), text, 'p', 'user');
                if (value === 0) {
                    setTimeout(() => {
                        handleStoreSessionSteps(randomNumber(), t('thank you'), 'p');
                    }, 1500);
                }
                if (value === 1) {
                    handleStoreSessionSteps(randomNumber(), t('Fill the form and click on send button'), 'form', 'bot', 'input', {
                        type: 'form',
                        custom: 'sendEmail',
                    });
                }
                if (value === 2) {
                    handleStoreSessionSteps(randomNumber(), t('How I can help you?'),
                        'radio',
                        'bot',
                        'radio',
                        {
                            type: 'question',
                            custom: 'firstQuestion',
                            question: '',
                            options: [
                                {
                                    value: 0,
                                    text: t('I have a question')
                                },
                                {
                                    value: 1,
                                    text: t('I want more information')
                                }
                            ]
                        });
                }
            }
        }

    };

    const showLastOptions = () => {

        handleStoreSessionSteps(randomNumber(), t('Was the information provided helpful'),
            'radio',
            'bot',
            'radio',
            {
                type: 'question',
                custom: 'end',
                question: '',
                options: [{
                    value: 0,
                    text: t('Yes')
                }, {
                    value: 1,
                    text: t('No, Contact via email')
                }, {
                    value: 2,
                    text: t('Back to the beginning')
                }]
            });

    };

    const createNewTicket = (type , content) => {
        handleStoreSessionSteps(randomNumber(), t('please wait creating ticket'), 'p');
        const params = {
            type: type,
            data: content,
            sessionId: sessionState.session.id
        };
        submitFormContent(params).then((res) => {
            setTimeout(() => {
                handleStoreSessionSteps(randomNumber(), t('ticket created') + res.id, 'p');
            }, 2000);
            setTimeout(() => {
                handleStoreSessionSteps(randomNumber(), t('will contact you asap'), 'p');
            }, 3000);
        });
    }

    const isEmail = (email) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(email);
    };

    const randomNumber = () => {
        const max = 9999999999999;
        return Math.floor(Math.random() * max);
    }

    const onIdle = () => {

        if(showLastMessage){
            showLastOptions();
        }
    }

    const onActive = (event) => {
        setShowLastMessage(false);
    }

    const onAction = (event) => {
        setShowLastMessage(false);
    }

    const {
        start,
        reset,
        activate,
        pause,
        resume,
        isIdle,
        isPrompted,
        isLeader,
        getTabId,
        getRemainingTime,
        getElapsedTime,
        getLastIdleTime,
        getLastActiveTime,
        getTotalIdleTime,
        getTotalActiveTime
    } = useIdleTimer({
        // onPrompt,
        onIdle,
        onActive,
        onAction,
        // timeout: 1000 * 60 * 20,
        timeout: 10000,
        promptTimeout: 0,
        events: [
            // 'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            // 'touchmove',
            // 'MSPointerDown',
            // 'MSPointerMove',
            // 'visibilitychange'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    })


    return (
        <FullScreen enabled={isFullScreen}>
            <Grid className={(language === 'ar') ? classes.main : classes.mainLTR} container component="main">
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={8}
                    sx={{
                        backgroundImage: 'url(/background-02.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={4} component={Paper} style={{background:'rgba(241,240,240,0.87)'}} elevation={6} square>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100vh',
                            maxHeight: '100vh',

                        }}
                    >
                        <CssBaseline/>

                        {/*Header Section*/}
                        <Box component="header" sx={{
                            py: 2,
                            px: 0,
                            mb: 'auto',
                            backgroundColor: '#152d52',
                            color: '#FFF',
                            fontSize: 'large',
                            fontWeight: 700,
                        }}>
                            <Container maxWidth="sm" className={'flex'} style={{justifyContent: 'space-between'}}>

                                <div className={classes.header}>
                                    <img src="/pia_icon.png" alt="image" />
                                    <h2>Pia</h2>
                                </div>

                                <div>

                                    {(language === 'ar') ? (
                                        <Button className={classes.headerButton} onClick={() => languageSwitcher('en')}
                                                style={{fontWeight: 700, color: 'white'}}>
                                            <Language/> EN
                                        </Button>
                                    ) : (
                                        <Button className={classes.headerButton} onClick={() => languageSwitcher('ar')}
                                                style={{fontWeight: 700, color: 'white'}}>
                                            <Language/> AR
                                        </Button>
                                    )}
                                    <Button className={classes.headerButton} onClick={() => showLastOptions()}
                                            style={{fontWeight: 700, color: 'white'}}>
                                        <CloseIcon/>
                                    </Button>
                                </div>

                            </Container>
                        </Box>
                        {/*End Header Section*/}

                        {/*Content Section*/}
                        <Container ref={chatRef} component="main" sx={{
                            mt: 2,
                            mb: 2,
                            overflow: 'auto',

                        }} maxWidth="sm">
                            {sessionState?.steps?.map((step, stepIndex) => (
                                <>
                                    {(step?.type === 'p') ? (
                                        <Typography
                                            key={step.id}
                                            className={`mb-2 ${(step.from === 'bot') ? (language === 'ar') ? classes.botBubble : classes.botBubbleLTR : (language === 'ar') ? classes.userBubble : classes.userBubbleLTR}`}
                                        >{step.message}</Typography>
                                    ) : (step?.type === 'radio') ? (
                                        <>
                                            {step.message !== '' && (
                                                <Typography
                                                    key={step.id}
                                                    className={`mb-2 ${(step.from === 'bot') ? (language === 'ar') ? classes.botBubble : classes.botBubbleLTR : (language === 'ar') ? classes.userBubble : classes.userBubbleLTR}`}
                                                >
                                                    {step.message}
                                                </Typography>
                                            )}

                                            {step?.trigger?.options.map((option) => (

                                                <Button
                                                    onClick={() => handleQuestionOptionSelect(option.value, option.text, stepIndex)}
                                                    style={{
                                                        textTransform: 'none',
                                                    }}
                                                    className={`rounded-lg mb-3 ml-2 mr-2  ${(language === 'ar') ? classes.optionButton : classes.optionButtonLTR}`}
                                                    variant="outlined" fullWidth>
                                                    {option.text}
                                                </Button>

                                            ))}
                                        </>
                                    ) : (step?.type === 'html') ? (
                                        <div
                                            className={`mb-2 ${(step.from === 'bot') ? (language === 'ar') ? classes.botBubble : classes.botBubbleLTR : (language === 'ar') ? classes.userBubble : classes.userBubbleLTR}`}
                                            dangerouslySetInnerHTML={{__html: step.message}}/>
                                    ) : (step?.type === 'form') ? (
                                        <>

                                            {step.message !== '' && (
                                                <Typography
                                                    key={step.id}
                                                    className={`mb-2 ${(step.from === 'bot') ? (language === 'ar') ? classes.botBubble : classes.botBubbleLTR : (language === 'ar') ? classes.userBubble : classes.userBubbleLTR}`}

                                                >
                                                    {step.message}
                                                </Typography>
                                            )}

                                            {step?.trigger?.custom === 'sendEmail' && (
                                                <GeneralForm handleResponce={(res) => {
                                                    createNewTicket('ticket', res)
                                                }}/>
                                            )}

                                        </>
                                    ) : ''}

                                    {(step?.form === 'forgetPassword') && (
                                        <ResetPasswordForm handleResponce={(res) => {
                                            handleStoreSessionSteps(randomNumber(), t('Reset password link was sent to your email'), 'p');
                                        }}/>)}

                                    {(step?.form === 'returnRequest') && (
                                        <ReturnRequestForm handleResponce={(res) => {
                                            createNewTicket('returnRequest', res)
                                        }}/>)}


                                    {(step?.form === 'passGuaranteeRequest') && (
                                        <PassGuaranteeRequestForm handleResponce={(res) => {
                                            createNewTicket('passGuaranteeRequest', res)
                                        }}/>)}


                                    {(step?.form === 'partnersSearch') && (
                                        <PartnerSearchForm handleResponce={(res) => {
                                            createNewTicket('partnersSearch', res)
                                        }}/>)}


                                    {(step?.form === 'activationForm') && (
                                        <ActivationForm handleResponce={(res) => {
                                            createNewTicket('activationForm', res)
                                        }}/>)}


                                    {(step?.form === 'bankWireProforma') && (
                                        <BankWireProformaForm handleResponce={(res) => {
                                            createNewTicket('bankWireProforma', res)
                                        }}/>)}


                                    {(step?.form === 'switch2Prc') && (
                                        <SwitchToPrcForm handleResponce={(res) => {
                                            createNewTicket('switch2Prc', res)
                                        }}/>)}


                                    {(step?.form === 'tataorForm') && (
                                        <TataorRequestForm handleResponce={(res) => {
                                            createNewTicket('tataorForm', res)
                                        }}/>)}


                                    {(step?.form === 'incorrectInformation') && (
                                        <IncorrectInformationForm handleResponce={(res) => {
                                            createNewTicket('incorrectInformation', res)
                                        }}/>)}


                                    {(step?.form === 'feedbackForm') && (
                                        <FeedbackForm handleResponce={(res) => {
                                            createNewTicket('feedbackForm', res)
                                        }}/>)}


                                    {(step?.form === 'renewalForm') && (
                                        <OneYearRenewRequestForm handleResponce={(res) => {
                                            createNewTicket('renewalForm', res)
                                        }}/>)}


                                    {(step?.form === 'freeRenewalForm') && (
                                        <ThreeMonthsRenewRequestForm handleResponce={(res) => {
                                            createNewTicket('freeRenewalForm', res)
                                        }}/>)}


                                    {(step?.form === 'issueReportForm') && (
                                        <IssueReportForm handleResponce={(res) => {
                                            createNewTicket('issueReportForm', res)
                                        }}/>)}


                                    {(step?.form === 'didNotPassForm') && (
                                        <DidNotPassForm handleResponce={(res) => {
                                            createNewTicket('didNotPassForm', res)
                                        }}/>)}


                                    {(step?.form === 'generalForm') && (
                                        <GeneralForm handleResponce={(res) => {
                                            createNewTicket('ticket', res)
                                        }}/>
                                    )}
                                </>
                            ))}
                        </Container>
                        {/*End Content Section*/}

                        {/*Footer section*/}
                        <Box component="footer" style={{paddingBottom: 40}} sx={{
                            py: 2,
                            mt: 'auto',

                        }}>
                            <Container>
                                <p className={'text-center'} style={{color: 'red'}}>{errorMessage}</p>
                                <Stack direction="row" spacing={2}>

                                    {(language === 'ar') ? (
                                        <Paper
                                            component="form"
                                            onSubmit={onMessageSubmit}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <IconButton type="submit" color="primary" sx={{p: '10px'}}
                                                        aria-label="send Message"
                                                        disabled={inputDisabled}
                                                        style={{transform: 'rotate(180deg)'}}
                                            >
                                                <SendIcon/>
                                            </IconButton>
                                            <Divider sx={{
                                                height: 28,
                                                m: 0.5
                                            }} orientation="vertical"/>

                                            <InputBase
                                                sx={{
                                                    mr: 1,
                                                    flex: 1
                                                }}
                                                placeholder={inputPlaceholder}
                                                inputProps={{'aria-label': inputPlaceholder}}
                                                value={messageText}
                                                onChange={onInputChange}
                                                disabled={inputDisabled}
                                                type={passwordInput ? 'password' : 'text'}
                                                style={{direction: 'rtl', fontSize: '0.9rem'}}
                                            />

                                        </Paper>

                                    ) : (
                                        <Paper
                                            component="form"
                                            onSubmit={onMessageSubmit}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <InputBase
                                                sx={{
                                                    ml: 1,
                                                    flex: 1
                                                }}
                                                placeholder={inputPlaceholder}
                                                inputProps={{'aria-label': inputPlaceholder}}
                                                value={messageText}
                                                onChange={onInputChange}
                                                disabled={inputDisabled}
                                                type={passwordInput ? 'password' : 'text'}
                                            />
                                            <Divider sx={{
                                                height: 35,
                                                m: 0.5
                                            }} orientation="vertical"/>
                                            <IconButton type="submit" color="primary" sx={{p: '10px'}}
                                                        aria-label="send Message"
                                                        disabled={inputDisabled}
                                            >
                                                <SendIcon/>
                                            </IconButton>
                                        </Paper>
                                    )}


                                </Stack>
                            </Container>
                        </Box>
                        {/*End Footer section*/}

                    </Box>

                </Grid>

            </Grid>
        </FullScreen>
    );

}

export default withTranslation()(Home);

