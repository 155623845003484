import React from 'react';
const enTranslations = {
  'HELLO_TITLE' : 'Hello, my name is Pia',
  'HELLO_SUBTITLE' : 'I am the PRC interactive assistant.',
  'I have a question' : 'I have a question',
  'I want more information' : 'I want more information',
  'I am a partner' : 'I am a partner',
  'FAQS_TITLE' : 'FAQs',
  'HELP_TITLE' : 'Support',
  'FAQS_SUBTITLE' : 'Choose from questions what you want to know. ',
  'BACK_TO_HOME' : 'Start Over',
  'MAIN_FAQS' : 'Main Faqs',
  'HELPFUL_QUESTION' : 'Was this answer helpful?',
  'YES' : 'Yes',
  'NO' : 'No',
  'What would you like me to call you' : 'What would you like me to call you?',
  'next' : 'Next',
  'back' : 'Back',
  'close' : 'Close',
  'Hi' : 'Hi ',
  'required' : 'The field is required',
  'How would you like to start?' : ', how would you like to start?',
  'Continue with my PRC account (sign in required)' : 'Continue with my PRC account (sign in required)',
  'Continue as a guest' : 'Continue as a guest',
  'Enter your login information' : 'Please enter your PRC account email and password',
  'Enter your login email' : 'Please enter your PRC account email',
  'Enter your login password' : 'Please enter your PRC account password',
  'Login' : 'Sign in',
  'Login and start' : 'Sign in and start',
  'Start' : 'Start',
  'login fields are required' : 'Email and password are required',
  'wait' : 'please wait...',
  'Login Failed' : 'Sign in failed,  the email or password is incorrect, or the entered email does not belong to any account',
  'How I can help you?' : 'How I can help you?',
  'Enter your email to get reset password url' : 'Enter your email to get reset password url',
  'Email': 'Email: ',
  'Try logging in again': 'Try logging in again',
  'Enter a valid email address':'Enter a valid email address',
  'Please wait, I am trying to access your PC account':'Please wait, I am trying to access your PRC account',
  'You are logged in successfully':'You are logged in successfully',
  'Password': 'Password: ',
  'Enter your name':'Enter your name',
  'Enter your account password':'Enter your account password',
  'I forgot my password':'I forgot my password',
  'Enter your PRC account email address':'Enter your PRC account email address',
  'choose from options' : 'Choose from options',
  'Was the information provided helpful' : 'Was the information provided helpful?',
  'Yes' : 'Yes',
  'No, Contact via email' : 'No, request customized support',
  'Back to the beginning' : 'Back to the beginning',
  'Fill the form and click on send button' : 'Fill in the form and click on the submit button ',
  'Write your message' : 'Write your message...',
  'Send' : 'Send',
  'thank you' : 'Glad to hear that, good luck on your study journey.',
  'please wait creating ticket' : 'Please wait, I am creating a custom support request for you with the data you entered...',
  'ticket created' : 'Your support request has been created, request number: ',
  'will contact you asap' : 'Our support team will contact you to answer your questions as soon as possible',
  'Reset password link was sent to your email':'Reset password link was sent to your email.',
  'Request Rest Password Link' : 'Request reset password link',
  'FIRST_NAME' : 'First Name',
  'LAST_NAME' : 'Last Name',
  'EMAIL' : 'Email Address',
  'CURRENT_EMAIL' : 'Current Email Address',
  'PRC_EMAIL' : 'PRC Account Email Address',
  'NEW_EMAIL' : 'New Email Address',
  'PHONE' : 'Phone Number',
  'COUNTRY' : 'Country',
  'PROOF_OF_PURCHASE' : 'Proof Of Purchase',
  'Do You Want To Get Printed Textbooks (Shipping Fees Applied)' : 'Do you want to get printed textbooks (shipping fees apply)?',
  'SUBMIT' : 'Submit',
  'UPLOAD' : 'Upload file (Pdf/word)',
  'UPLOADING' : 'Uploading..',
  'ACTIVATION_CODE' : 'Activation Code',
  'PARTS' : 'Choose a part',
  'ORDER_NUMBER' : 'Order Number',
  'CERTIFICATE' : 'Certificate',
  'FEEDBACK' : 'Feedback type',
  'First name is required.' : 'First name is required.',
  'Last name is required.' : 'Last name is required.',
  'Email is required.' : 'Email is required.',
  'Phone is required.' : 'Phone is required.',
  'Country is required.' : 'Country is required. ',
  'Choose a certificate.' : 'Choose a certificate.',
  'Choose a part.' : 'Choose a part.',
  'Choose feedback type' : 'Choose feedback type.',
  'Activation code is required' : 'Activation code is required.',
  'Upload file is required' : 'File is required.',
  'Your message was sent successfully, Support team will respond asap.' : 'Your message was sent successfully, Our support team will respond as soon as possible.',
}
export default enTranslations;