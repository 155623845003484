import { createSlice, configureStore } from '@reduxjs/toolkit'
import { getUserGeolocationDetails } from '../services/api';

const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    session:{},
    geoData:{},
    userDetails: {},
    steps: [],
  },
  reducers: {
    storeUserGeoData: (state , action) => {
      state.geoData = action.payload;
    },
    storeUserDetails: (state , action) => {
      state.userDetails = action.payload;
    },
    storeUserSteps: (state , action) => {
      state.steps = [...state.steps , action.payload];
    },
    storeUserSession: (state , action) => {
      state.session = action.payload;
    }
  }
})

export const {storeUserGeoData , storeUserDetails , storeUserSteps, storeUserSession } = userDataSlice.actions

const store = configureStore({
  reducer: userDataSlice.reducer
})

// Can still subscribe to the store
store.subscribe(() => console.log(store.getState()))

export default userDataSlice.reducer