import React from 'react';
const arTranslations = {
  'HELLO_TITLE' : 'مرحباً، اسمي Pia',
  'HELLO_SUBTITLE' : 'أنا المساعدة التفاعلية في  PRC. ',
  'I have a question' : 'أحتاج إلى المساعدة',
  'I want more information' : 'أرغب في الحصول على المزيد من المعلومات',
  'I am a partner' : 'انا شريك',
  'FAQS_TITLE' : 'أسئلة وأجوبة',
  'HELP_TITLE' : 'الدعم',
  'FAQS_SUBTITLE' : 'اختر من بين الأسئلة ما تريد أن تعرفه. ',
  'BACK_TO_HOME' : 'ابدأ من جديد',
  'MAIN_FAQS' : 'الأسئلة الشائعة الرئيسية',
  'HELPFUL_QUESTION' : 'هل هذه الإجابة كانت مفيدة؟',
  'YES' : 'نعم',
  'NO' : 'لا',
  'What would you like me to call you' : 'ما الاسم الذي ترغب أن أدعوك به؟',
  'next' : 'التالي',
  'back' : 'رجوع',
  'close' : 'إغلاق',
  'Hi' : 'مرحبا ',
  'required' : 'هذا الحقل مطلوب',
  'How would you like to start?' : ' كيف تريد أن تبدأ؟',
  'Continue with my PRC account (sign in required)' : 'المتابعة مع حسابي في PRC (تسجيل الدخول مطلوب)',
  'Continue as a guest' : 'المتابعة كضيف',
  'Enter your login information' : 'الرجاء إدخال البريد الإلكتروني وكلمة المرور لحساب PRC',
  'Enter your login email' : 'الرجاء إدخال البريد الإلكتروني لحساب PRC الخاص بك',
  'Enter your login password' : 'الرجاء إدخال كلمة مرور حساب PRC الخاص بك',
  'Login' : 'تسجيل الدخول',
  'Login and start' : 'سجل الدخول وابدأ',
  'Start' : 'البدء',
  'login fields are required' : 'البريد الإلكتروني وكلمة المرور مطلوبتان',
  'wait' : 'أرجو الإنتظار...',
  'Login Failed' : 'فشل تسجيل الدخول ، ربما هناك خطأ في البريد الإلكتروني أو كلمة المرور ، أو أن البريد الإلكتروني الذي تم إدخاله لا ينتمي إلى أي حساب',
  'How I can help you?' : 'كيف استطيع مساعدتك؟',
  'Enter your email to get reset password url' : 'أدخل بريدك الإلكتروني للحصول على رابط لإعادة تعيين كلمة المرور',
  'Email': 'البريد الإلكتروني:',
  'Try logging in again': 'حاول تسجيل الدخول مرة أخرى',
  'Enter a valid email address':'أدخل عنوان بريد إلكتروني صالح',
  'Please wait, I am trying to access your PC account':'يرجى الإنتظار، أحاول الوصول إلى حساب PRC الخاص بك',
  'You are logged in successfully':'تم تسجيل الدخول بنجاح',
  'Password': 'كلمة المرور:',
  'Enter your name':'أدخل اسمك',
  'Enter your account password':'أدخل كلمة مرور حسابك',
  'I forgot my password':'نسيت كلمة المرور',
  'Enter your PRC account email address':'أدخل عنوان البريد الإلكتروني لحساب PRC الخاص بك',
  'choose from options' : 'اختر أحد الخيارات',
  'Was the information provided helpful' : 'هل المعلومات المقدمة مفيدة؟',
  'Yes' : 'نعم',
  'No, Contact via email' : 'لا, انشاء طلب دعم مخصص',
  'Back to the beginning' : 'العودة من البداية',
  'Fill the form and click on send button' : 'املأ النموذج وانقر على زر الإرسال',
  'Write your message' : 'اكتب رسالتك...',
  'Send' : 'إرسال',
  'thank you' : 'سعيدة بسماع ذلك، أتمنى لك التوفيق في رحلتك الدراسية.',
  'please wait creating ticket' : 'يرجى الانتظار، أقوم الآن بإنشاء طلب دعم مخصص لك متضمن البيانات التي أدخلتها...',
  'ticket created' : 'تم إنشاء طلب دعم مخصص، رقم الطلب: ',
  'will contact you asap' : 'سيقوم فريق الدعم بالتواصل معك للإجابة على أسئلتك بأسرع وقت ممكن',
  'Reset password link was sent to your email':'تم إرسال رابط استعادة كلمة المرور إلى بريدك الإلكتروني.',
  'Request Rest Password Link' : 'طلب رابط إعادة تعيين كلمة المرور',
  'FIRST_NAME' : 'الاسم الاول',
  'LAST_NAME' : 'اللقب (اسم العائلة)',
  'EMAIL' : 'البريد الإلكتروني',
  'CURRENT_EMAIL' : 'البريد الإلكتروني الحالي',
  'PRC_EMAIL' : 'البريد الإلكتروني لحسابك على PRC',
  'NEW_EMAIL' : 'البريد الإلكتروني الجديد',
  'PHONE' : 'رقم الهاتف',
  'COUNTRY' : 'الدولة',
  'PROOF_OF_PURCHASE' : 'تأكيد الشراء',
  'Do You Want To Get Printed Textbooks (Shipping Fees Applied)' : 'هل ترغب في الحصول على الكتب المطبوعة (تطبق رسوم الشحن)؟',
  'SUBMIT' : 'إرسال',
  'UPLOAD' : 'تحميل ملف (Pdf / word)',
  'UPLOADING' : 'جارٍ التحميل ..',
  'ACTIVATION_CODE' : 'رمز التفعيل',
  'PARTS' : 'اختر أحد الأجزاء',
  'ORDER_NUMBER' : 'رقم الطلب',
  'CERTIFICATE' : 'الشهادة',
  'FEEDBACK' : 'نوع الملاحظة',
  'First name is required.' : 'الاسم الأول مطلوب.',
  'Last name is required.' : 'اللقب (اسم العائلة) مطلوب.',
  'Email is required.' : 'البريد الإلكتروني مطلوب.',
  'Phone is required.' : 'الهاتف مطلوب.',
  'Country is required.' : 'الدولة مطلوبة.',
  'Choose a certificate.' : 'اختر شهادة.',
  'Choose a part.' : 'اختر أحد الأجزاء.',
  'Choose feedback type' : 'اختر نوع الملاحظة.',
  'Activation code is required' : 'رمز التفعيل مطلوب.',
  'Upload file is required' : 'مطلوب تحميل الملف.',
  'Your message was sent successfully, Support team will respond asap.' : 'تم إرسال رسالتك بنجاح، وسيقوم فريق الدعم بالرد في أسرع وقت ممكن.',
}
export default arTranslations;