import i18n from "i18next";
import enTranslation from "./en";
import arTranslation from "./ar";
import deTranslation from "./de";
import jpTranslation from "./jp";
import geTranslation from "./ge";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: enTranslation
    },
    ar: {
        translation: arTranslation
    },
    // de: {
    //     translation: deTranslation
    // },
    // jp: {
    //     translation: jpTranslation
    // },
    // ge: {
    //     translation: geTranslation
    // }
};

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        detection: DETECTION_OPTIONS,
        resources,
        // lng: "ar",
        fallbackLng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;