import axios from 'axios';
axios.defaults.baseURL = 'https://pia-api-server.herokuapp.com/api/v1';
// axios.defaults.baseURL = 'http://localhost:1337/api/v1';

export const getAllQuestions = async () => {
  try {
    const response = await axios.get(
      '/public/questions',
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const getHelpQuestions = async (params) => {
  try {
    const response = await axios.get(
      '/public/questions',
      {params}
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const getMultipleQuestions = async (params) => {
  console.log(params);
  try {
    const response = await axios.get(
      '/public/questions/multiQuestions',
      {params}
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const getRelatedQuestionDetails = async (id) => {
  try {
    const response = await axios.get(
      '/public/questions/'+id,
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const submitFileUpload = async (file) => {
  try {
    const response = await axios.post('/file/fileUpload', file);
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const generateNewSession = async () => {
  try {
    const response = await axios.post('/public/sessions');
    return response.data;
  } catch (error) {
    return null;
  }
};

export const createNewActivity = async (data) => {
  try {
    const response = await axios.post('/public/activities' , data);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateActivity = async (id , data) => {
  try {
    const response = await axios.patch(`/public/activities/${id}` , data);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const submitFormContent = async (data) =>{
  try {
    const response = await axios.post('/public/submissions/' , data);
    return response.data;
  } catch (error) {
    return null;
  }
};

// PRC SERVER API REQUESTS
export const checkLoginDetails= async (email, password) => {
  try {
    const response = await axios.post(
      '/public/external/login',
      {
        email:email,
        password:password
      },
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

export const sendResetPasswordEmail = async (email) => {
  try {

    const response = await axios.post(
        '/public/external/resetPassword',
        {
          email:email,
        },
    );
    console.log('response',  response);

    return response.data;
  } catch (error) {
    return null;
  }
}



// GEO LOCATION API
export const getUserGeolocationDetails = async () => {
  try {
    // const response = await axios.get('/public/external/geoLocate');
    const response = await axios.get('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
