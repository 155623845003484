import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {Autocomplete, Button, IconButton, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {sendResetPasswordEmail} from "../services/api";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import countries from './countries.json';
import Box from "@mui/material/Box";
function ChooseCountryField(props) {

    const {
        t,
        language
    } = props.i18n;

    return (
            <Autocomplete
                id="country-select-demo"
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => props.handleResponse(value.label)}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label} ({option.code})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        className={'mt-2'}
                        label={t('COUNTRY')}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
    );
}

export default withTranslation()(ChooseCountryField);