import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {Autocomplete, Button, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {sendResetPasswordEmail} from "../services/api";
import UploadFileField from "../services/uploadFileField";


function PassGuaranteeRequestForm(props) {

    const {
        t, language
    } = props.i18n;


    const [error, setError] = useState(null);
    const [formContent, setFormContent] = useState({type: 'passGuaranteeRequest'});
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleFormChange = (ev) => {
        setError(null);
        const newForm = {...formContent};
        newForm[ev.target.name] = ev.target.value;
        setFormContent(newForm);
    };

    const handleAutoCompleteFormChange = (name, value) => {
        setError(null);

        const newForm = {...formContent};
        newForm[name] = value.label;
        setFormContent(newForm);
    };

    const handleFromSubmit = () => {
        if (!formContent.email) {
            setError(t('Email is required.'));
            return;
        } else {
            if (!isEmail(formContent.email)) {
                setError(t('Enter a valid email address'));
            }
        }
        if (!formContent.subject) {
            setError(t('Choose a certificate.'));
            return;
        }
        if (!formContent.part) {
            setError(t('Choose a part.'));
            return;
        }

        setSubmitDisabled(true);
        props.handleResponce(formContent);
    };

    const parts = [{label: 'Part 1'}, {label: 'Part 2'}, {label: 'Part 3'},];
    const subjects = [{label: 'CIA'}, {label: 'CIA ARABIC'}, {label: 'CMA'},];


    const isEmail = (email) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(email);
    };


    return (
        <>
            <TextField
                label={t('PRC_EMAIL')}
                type={'email'}
                name="email"
                fullWidth
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />


            <Autocomplete
                className={'mt-2'}
                disablePortal
                options={subjects}
                onChange={(event, value) => handleAutoCompleteFormChange('subject', value)}
                renderInput={(params) => <TextField {...params} label={t('CERTIFICATE')}/>}
            />


            <Autocomplete
                className={'mt-2'}
                disablePortal
                onChange={(event, value) => handleAutoCompleteFormChange('part', value)}
                options={parts}
                renderInput={(params) => <TextField {...params} label={t('PARTS')}/>}
            />


            <UploadFileField handleResponse={(res) => {
                setError(null);
                const newForm = {...formContent};
                newForm.file = res;
                setFormContent(newForm);
            }}/>

            <TextField
                label={t('Write your message')}
                fullWidth
                multiline
                rows={3}
                name="message"
                onChange={(e) => handleFormChange(e)}
                className={'mt-2'}
            />

            <p style={{color: 'red', textAlign: 'center'}}>{error}</p>

            <Button
                disabled={submitDisabled}
                variant="contained"
                className={'mt-2 mb-3'}
                onClick={handleFromSubmit}
                fullWidth
            >
                {t('SUBMIT')}
            </Button>
        </>
    );
}

export default withTranslation()(PassGuaranteeRequestForm);