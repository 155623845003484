import React, {useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {Button, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {sendResetPasswordEmail} from "../services/api";


function PartnerSearchForm(props) {

    const {
        t,
        language
    } = props.i18n;
    const [email , setEmail] = useState(null);
    const [error, setError] = useState(null);
    const handleResetPasswordSubmit = () =>{
        if(isEmail(email)){
            setError(null);
            sendResetPasswordEmail(email).then((res) => {
                props.handleResponce(res);

                // console.log('reset' , res)
                // handleStoreSessionSteps(randomNumber(), t('Reset password link was sent to your email'), 'p');
            });
        }else{
            setError(t('Enter a valid email address'));
        }
    }

    const isEmail = (email) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(email);
    };


    return (
        <>
            <TextField
                style={{direction: 'rtl'}}
                type={'email'}
                id="outlined-multiline-static"
                label={t('Enter your email')}
                fullWidth
                onChange={(e) => {setEmail(e.target.value); setError(null)}}
                className={'mt-2'}
                error={error?true:false}
                helperText={error}
            />

            <Button variant="contained" className={'mt-2 mb-3'}
                    onClick={handleResetPasswordSubmit}
                    fullWidth
                   >
                {t('Request Rest Password Link')}
            </Button>
        </>
    );
}

export default withTranslation()(PartnerSearchForm);